<template>
  <div class="p-4">
    <h2 class="text-center">تسجيل الدخول</h2>
    <div
      class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-8">
        <form class="mt-8 space-y-6" method="POST" @submit.prevent="login()">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="phone-address" class="sr-only"> رقم الهاتف</label>
              <input
                id="phone-address"
                name="phone"
                type="phone"
                autocomplete="phone"
                required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="رقم الهاتف "
                v-model="phone"
              />
            </div>
            <div>
              <label for="password" class="sr-only">كلمة السر</label>
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="كلمه السر"
                v-model="password"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg
                  class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              تسجيل الخول
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data() {
    return {
      phone: null,
      password: null,
    };
  },
  methods: {
    async login() {
      let user = {
        phone: this.phone,
        password: this.password,
      };
      await this.$store.dispatch("login", user);
      if (localStorage.getItem("user_token") !== null) {
        this.responseAlert(" تم ", " تم نسجيل الدخول بنجاح ", "success");
        await this.$router.push("/");
        location.reload();
      } else {
        this.phone = "";
        this.password = "";
        this.responseAlert(
          " خطاء ",
          "  رقم الهاتف او كلمة السر غير صحيحة ",
          "error"
        );
        this.errorLogin = "error";
      }
    },
  },
  inject: ["responseAlert"],
};
</script>

<style></style>
