<template>
  <div class="p-4 w-full">
    <h2 class="text-center">اضف منتج جديد</h2>
    <add-product-input />
  </div>
</template>

<script>
import AddProductInput from "@/components/AddProduct/AddProductInput.vue";
export default {
  components: { AddProductInput },
  inject: ["checkCookie"],
  created() {
    this.checkCookie();
  },
};
</script>

<style></style>
