<template>
  <div class="w-full">
    <div>
      <home-services></home-services>
    </div>
    <div class="grid md:grid-cols-2 grid-cols-1 p-4">
      <!-- <h4>
        <span> سعر الدولار </span>
        <input class="ms-2" type="text" />
      </h4> -->
      <div class="p-4" dir="rtl">
        <div class="mb-6">
          <label
            for="large-input"
            class="block mb-2 text-normal font-medium text-gray-900 dark:text-white"
          >
            سعر الدولار
          </label>
          <input
            dir="rtl"
            v-model="config.value"
            @change="updateConfig()"
            type="number"
            step=".01"
            id="large-input"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeServices from "../components/HomePage/HomeServices.vue";
import axios from "axios";

export default {
  name: "HomeView",
  data() {
    return {
      user_data: {
        email: null,
      },
      config: { value: 0 },
    };
  },
  methods: {
    async fetchConfig() {
      try {
        const response = await axios.get(`/api/v1/config`);
        if (response?.data?.success) {
          this.config = response?.data?.config;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateConfig() {
      try {
        const response = await axios.put(`/api/v1/config/${this.config?.id}`, {
          value: this.config?.value,
        });
        if (response?.data?.success) {
          this.responseAlert("تم التعديل بنجاح", "تم", "success");
        } else {
          this.responseAlert("خطاء اثناء التعديل", "خطاء", "error");
        }
      } catch (err) {
        this.responseAlert("خطاء اثناء التعديل", "خطاء", "error");
        console.log(err);
      }
    },
  },
  components: {
    HomeServices,
  },
  inject: ["responseAlert"],
  created() {
    this.fetchConfig();
  },
};
</script>
